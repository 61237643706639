// import Bootstrap 4  
import 'bootstrap';



// Make global jQuery
import jQuery from 'jquery';
window.$ = jQuery;

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel'


$(window).on('load', function () {
    let _stagePadding = 50;
    let _margin = 20;

    if ($(window).width() >= 768) {
    	_stagePadding = 0;
    	_margin = 40;
    }


	$('.owl-carousel').owlCarousel({
	    loop:true,
	    margin:10,
	    nav:true,
	    center: true,
	    margin: _margin,
	    stagePadding: _stagePadding,
	    responsive:{
	        0:{
	            items:1
	        },
	        768:{
	            items:3,
	        },
	        992:{
	            items:3
	        }
	    }
	})

});


